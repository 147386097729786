import * as React from "react";
import {Field, UpdateFormState} from "../../types/Form";
import {FormControl, FormLabel, Rating} from "@mui/material";
import Typography from "@mui/material/Typography";

export function FormFieldRating(props: { field: Field, formState: any, updateFormState: UpdateFormState, maxValue: number }) {
  const {field, formState, updateFormState} = props;

  return <FormControl error={formState.errors[field.key]} fullWidth sx={{mb: 2}}>
    <FormLabel id={field.key}>
      <Typography >{field.question}</Typography>
    </FormLabel>
    <Rating
      id={field.key}
      name={field.key}
      value={formState.form[field.key] ? parseInt(formState.form[field.key]) : 0}
      onChange={(event, newValue) => updateFormState({
        value: {
          optionValue: newValue ? newValue.toString() : '',
          optionString: field.key,
        }
      })}
      max={props.maxValue}
    />
  </FormControl>
}
