import {gql} from "@apollo/client";

export const GET_LOGGED_USER_ANIMAL_DETAILS_BY_ID = gql`
  query getLoggedIUserAnimalById($petId: Int!) {
    loggedUser {
      animalById(animalId: $petId) {
        workMinutes
        worklogsCount
      }
    }
  }
`;
