import {gql} from "@apollo/client";

export const GET_PADA_FORM = gql`
  query getPadaForm {
    definitions {
      padaExamForm {
        title
        sections {
          title
          fields {
            key
            question
            definition
            type
            options {
              optionValue
              optionString
            }
            value
          }
        }
      }
    }
  }
`;
