import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import {useState} from "react";
import {
  Alert,
  Backdrop,
  CircularProgress,
  Fade,
  MenuItem,
  Modal,
  Select
} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {PETS} from "../App";
import {MobileDatePicker} from "@mui/lab";
import format from 'date-fns/format'
import {DATE_FORMAT} from "../tools/timeFomrats";
import {Animal, getAvatar} from "../types/Animal";
import dog from '../images/pies.jpg';
import cat from '../images/kot.jpg';
import Avatar from "@mui/material/Avatar";
import {Edit} from "@mui/icons-material";

export const AnimalFormStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

interface PetFormParams {
  loading: boolean,
  animal: Animal | null,
  onSubmit: any,
  id: number
}

export default function PetForm(params: PetFormParams) {
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const {loading, animal, onSubmit, id} = params;

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    try {
      await onSubmit({variables: {
          animal: {
            id: id,
            name: data.get('name'),
            officialName: data.get('officialName'),
            species: data.get('species'),
            breed: data.get('breed'),
            chipNo: data.get('chipNo'),
            dob: date ? format(date, DATE_FORMAT) : null,
          }
        }});
      navigate('/' + PETS);
    } catch (e) {
      // @ts-ignore
      setError(e.message);
    }
  };

  const [date, setDate] = React.useState<Date | null>(animal ? new Date(animal.dob) : new Date());
  React.useEffect(() => {
    setDate(animal ? new Date(animal.dob) : null);
  }, [animal]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={true}
      onClose={() => navigate('/' + PETS)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={true}>
        {loading ? <CircularProgress/> :
          <Box component="form" onSubmit={handleSubmit} sx={AnimalFormStyle}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={8}>
                <Avatar
                  id="avatar"
                  alt={"avatar"}
                  src={getAvatar(animal)}
                  sx={{width: 220, height: 220}}
                />
              </Grid>
              <Grid item>
                {id > 0
                  ? <Button onClick={() => navigate('/' + PETS + '/avatar/' + id)}><Edit /></Button>
                  : <React.Fragment />
                }
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="name"
                  label="Name"
                  name="name"
                  defaultValue={animal?.name}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="officialName"
                  label="Official Name"
                  name="officialName"
                  defaultValue={animal?.officialName}
                />
              </Grid>
              <Grid item xs={12}>
                <Select
                  fullWidth
                  id="species"
                  label="Species"
                  name="species"
                  defaultValue={animal?.species}
                >
                  <MenuItem value={"dog"}>Dog</MenuItem>
                  <MenuItem value={"cat"}>Cat</MenuItem>
                  <MenuItem value={"other"}>Other</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="breed"
                  label="Breed"
                  name="breed"
                  defaultValue={animal?.breed}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="chipNo"
                  label="Chip No"
                  name="chipNo"
                  defaultValue={animal?.chipNo}
                />
              </Grid>
              <Grid item xs={12}>
                <MobileDatePicker
                  label="Date of Birth"
                  value={date}
                  onChange={(newDate) => {
                    setDate(newDate);
                  }}
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
              </Grid>
              <Grid item xs={12}>
              </Grid>
            </Grid>
            {error ?
              <Alert onClose={() => setError('')} severity="error">
                {error}
              </Alert>
              : null}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{mt: 3, mb: 2}}
            >
              Save
            </Button>
          </Box>
        }
      </Fade>
    </Modal>
  );
}
