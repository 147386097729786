import * as React from "react";
import Grid from "@mui/material/Grid";
import {
  Card,
  CardActionArea,
  CardHeader,
} from "@mui/material";
import {WORK_LOG} from "../../App";
import {
  Group,
  Person, ReduceCapacity,
} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import FileUploader from "../ImageUpload/FileUploader";

export default function WorklogDashboard() {
  const navigate = useNavigate();
  return  <Grid container spacing={3}>
    <Grid item key='group' md={4} xs={12}>
      <Card
        sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
      >
        <CardActionArea onClick={() => navigate('/' + WORK_LOG + '/new_group/')}>
          <CardHeader
            avatar={<ReduceCapacity  fontSize={'large'} />}
            title='Zajęcia grupowe'
            titleTypographyProps={{sx: {fontSize: '2rem'}, color:'primary'}}
            >
          </CardHeader>
        </CardActionArea>
      </Card>
    </Grid>
    <Grid item key='ind' md={4} xs={12}>
      <Card
        sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
      >
        <CardActionArea onClick={() => navigate('/' + WORK_LOG + '/new/')}>
          <CardHeader
            avatar={<Group fontSize={'large'} />}
            title='Zajęcia indywidualne'
            titleTypographyProps={{sx: {fontSize: '2rem'}, color:'primary'}}
          >
          </CardHeader>
        </CardActionArea>
      </Card>
    </Grid>
    <Grid item key='solo' md={4} xs={12}>
      <Card
        sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
      >
        <CardActionArea onClick={() => navigate('/' + WORK_LOG + '/new_solo/')}>
          <CardHeader
            avatar={<Person fontSize={'large'} />}
            title='Wolontariat'
            titleTypographyProps={{sx: {fontSize: '2rem'}, color:'primary'}}
          >
          </CardHeader>
        </CardActionArea>
      </Card>
    </Grid>
    {/*<Grid item key='up' md={4} xs={12}>*/}
    {/*  <FileUploader />*/}
    {/*</Grid>*/}
  </Grid>
}
