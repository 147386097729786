import * as React from 'react';
import {useQuery} from "@apollo/client";
import {useParams} from "react-router-dom";
import WorkLog, {WorkLogFormUid} from "./WorkLog";
import {CircularProgress} from "@mui/material";
import Typography from "@mui/material/Typography";
import {GET_WORK_LOG_FORM} from "../../../gql/queries/getWorkLogForm";

export default function EditWorkLogForm() {
  const params = useParams();
  const {uid} = params;

  if (!uid) {
    throw new Error('Invalid UID!');
  }

  const formData = localStorage.getItem(WorkLogFormUid + uid);

  if (null === formData) {
    throw new Error('Invalid UID!');
  }

  const {loading, data} = useQuery(GET_WORK_LOG_FORM);

  return (
    <React.Fragment>
      {loading ? <CircularProgress /> : null}
      {!loading && !data ?
        <Typography component="p" variant="h4">
          No data
        </Typography>
        : null }
      {data ? <WorkLog
        uid={uid}
        form={data.definitions.workLogForm}
        initialFormData={JSON.parse(formData)}
        readOnly={false}
      />: null
      }
    </React.Fragment>
  );
}
