import {gql} from "@apollo/client";

export const GET_LOGGED_USER_WORKLOG_DATA = gql`
  query getLoggedIUserWorklogData($worklogId: Int!) {
    loggedUser {
      worklogData(worklogId: $worklogId) {
        key
        value
      }
    }
  }
`;
