import * as React from 'react';
import {useQuery} from "@apollo/client";
import {useParams} from "react-router-dom";
import Aat from "./Aat";
import {CircularProgress} from "@mui/material";
import Typography from "@mui/material/Typography";
import {generateUniqueID} from "web-vitals/dist/lib/generateUniqueID";
import {getInitialState} from "./NewAat";
import {GET_AAT_FORM} from "../../gql/queries/getAatForm";
import {GET_INSTRUCTOR_AAT_DATA} from "../../gql/queries/getInstructorAatData";

function transformFormData(data: any, formData: any, id: number) {
  let x = getInitialState(data);
  formData.map(
    (value: {key: string, value: string}) => x[value.key] = value.value
  );
  x.remoteId = id;
  return x;
}

export default function EditDbAat() {
  const params = useParams();
  const {id} = params;

  if (!id) {
    throw new Error('Invalid ID!');
  }
  const uid = generateUniqueID();

  const {loading: dataLoading, data: formData} = useQuery(
    GET_INSTRUCTOR_AAT_DATA,
    {variables: {examId: parseInt(id)}}
  );

  const {loading, data} = useQuery(GET_AAT_FORM);

  return (
    <React.Fragment>
      {loading || dataLoading ? <CircularProgress /> : null}
      {!loading && !dataLoading && !data ?
        <Typography component="p" variant="h4">
          No data
        </Typography>
        : null }
      {data && formData ? <Aat
        uid={uid}
        form={data.definitions.aatExamForm}
        initialFormData={transformFormData(data.definitions.aatExamForm, formData.instructor.aatExamData, parseInt(id))}
      />: null
      }
    </React.Fragment>
  );
}
