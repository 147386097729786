import {gql} from "@apollo/client";

export const GET_LOGGED_USER_ANIMALS = gql`
  query getLoggedIUserAnimals {
    loggedUser {
      animals {
        id
        name
        officialName
        breed
        chipNo
        dob
        species
        certificateList
        avatar
      }
    }
  }
`;
