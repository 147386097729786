import * as React from 'react';
import Typography from '@mui/material/Typography';
import {CircularProgress,} from "@mui/material";
import {useQuery} from "@apollo/client";
import {GET_PADA_FORM} from "../../gql/queries/getPadaForm";
import {Form} from "../../types/Form";
import PadaForm from "./PadaForm";
import {generateUniqueID} from "web-vitals/dist/lib/generateUniqueID";

export function getInitialState(data: Form) {
  let initialState: any = {};
  for (let section of data.sections) {
    for (let field of section.fields) {
      initialState[field.key] = field.value ?? null;
    }
  }
  return initialState;
}

export default function NewPadaExam() {
  const uid = generateUniqueID();
  const {loading, data} = useQuery(GET_PADA_FORM);

  return (
    <React.Fragment>
      {loading ? <CircularProgress /> : null}
      {!loading && !data ?
        <Typography component="p" variant="h4">
          No data
        </Typography>
       : null }
      {data ? <PadaForm
          readOnly={false}
          uid={uid}
          form={data.definitions.padaExamForm}
          initialFormData={getInitialState(data.definitions.padaExamForm)}
        />: null
      }
    </React.Fragment>
  );
}
