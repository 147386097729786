import * as React from 'react';
import {PADA_EXAM} from "../../App";
import {useNavigate} from "react-router-dom";
import {
  Card,
  CardActions,
  CardContent, CircularProgress,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {DeleteForever, Restore} from "@mui/icons-material";
import {deleteLocalForm} from "./LocalForms";
import {useQuery} from "@apollo/client";
import {PadaFormUid} from "../PadaForm/PadaForm";
import {GET_DOG_BY_ID} from "../../gql/queries/getAnimalById";

export default function PadaLocalCard({jsonData, setState}:any) {
  const id = jsonData.key.substring(PadaFormUid.length);
  const json = JSON.parse(jsonData.data);
  const date = json.date;
  const navigate = useNavigate();
  const petId = parseInt(json.animal_id);
  const {loading: animalLoading, data: animalData} = useQuery(
    GET_DOG_BY_ID,
    {
      variables: {petId: petId},
      skip: petId < 1,
    }
  );
  return <Grid item key={id} xs={12} sm={6} md={3}>
    <Card
      sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
    >
      <CardContent sx={{ flexGrow: 1 }}>
        <Typography gutterBottom variant="h6" component="h3">
          {date} PADA exam
        </Typography>
        <Typography>
          {animalLoading ? <CircularProgress/> :
            animalData?.evaluator.animalById ?
              `${animalData.evaluator.animalById.owner.lastName} ${animalData.evaluator.animalById.owner.firstName} with ${animalData.evaluator.animalById.name}`
              : "Animal not selected..."}
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" onClick={() => navigate('/' + PADA_EXAM + '/local/' + id)}><Restore /></Button>
        <Button size="small" onClick={()=>deleteLocalForm(jsonData.key, setState)}><DeleteForever /></Button>
      </CardActions>
    </Card>
  </Grid>
}
