import {gql} from "@apollo/client";

export const GET_LOGGED_USER = gql`
  query getLoggedUser {
    loggedUser {
      id
      name
      permissions
    }
  }
`;
