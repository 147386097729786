import * as React from 'react';
import {useQuery} from "@apollo/client";
import {useParams} from "react-router-dom";
import {GET_PADA_FORM} from "../../gql/queries/getPadaForm";
import {CircularProgress} from "@mui/material";
import Typography from "@mui/material/Typography";
import {generateUniqueID} from "web-vitals/dist/lib/generateUniqueID";
import {GET_PADA_EXAM_BY_ID} from "../../gql/queries/getPadaExamById";
import {getInitialState} from "../WorkLogForm/Individual/NewWorkLog";
import PadaForm from "./PadaForm";

function transformFormData(data: any, formData: any, id: number) {
  let x = getInitialState(data);
  formData.map(
    (value: {key: string, value: string}) => x[value.key] = value.value
  );
  x.remoteId = id;
  return x;
}

type props = {
  readOnly: boolean
}

export default function EditDbPadaForm(props: props) {
  const params = useParams();
  const {id} = params;
  const {readOnly} = props;

  if (!id) {
    throw new Error('Invalid ID!');
  }
  const uid = generateUniqueID();

  const {loading: dataLoading, data: formData} = useQuery(
    GET_PADA_EXAM_BY_ID,
    {variables: {examId: parseInt(id)}}
  );

  const {loading, data} = useQuery(GET_PADA_FORM);

  return (
    <React.Fragment>
      {loading || dataLoading ? <CircularProgress /> : null}
      {!loading && !dataLoading && !data ?
        <Typography component="p" variant="h4">
          No data
        </Typography>
        : null }
      {data && formData ? <PadaForm
        readOnly={readOnly}
        uid={uid}
        form={data.definitions.padaExamForm}
        initialFormData={transformFormData(data.definitions.padaExamForm, formData.evaluator.padaExamData, parseInt(id))}
      />: null
      }
    </React.Fragment>
  );
}
