import * as React from 'react';
import {useQuery} from "@apollo/client";
import {useParams} from "react-router-dom";
import {CircularProgress} from "@mui/material";
import Typography from "@mui/material/Typography";
import {generateUniqueID} from "web-vitals/dist/lib/generateUniqueID";
import {getInitialState} from "../Individual/NewWorkLog";
import { GET_LOGGED_USER_WORKLOG_GROUP_DATA } from '../../../gql/queries/getLoggedUserWorklogGroupData';
import { GET_WORK_LOG_GROUP_FORM } from '../../../gql/queries/getWorkLogGroupForm';
import WorkLogGroup from './WorkLogGroup';

function transformFormData(data: any, formData: any, id: number) {
  let x = getInitialState(data);
  formData.map(
    (value: {key: string, value: string}) => x[value.key] = value.value
  );
  x.remoteId = id;
  return x;
}

type props = {
  readOnly: boolean
}

export default function EditDbWorkLogGroupForm(props: props) {
  const params = useParams();
  const {id} = params;
  const {readOnly} = props;

  if (!id) {
    throw new Error('Invalid ID!');
  }
  const uid = generateUniqueID();

  const {loading: dataLoading, data: formData} = useQuery(
    GET_LOGGED_USER_WORKLOG_GROUP_DATA,
    {variables: {worklogId: parseInt(id)}}
  );

  const {loading, data} = useQuery(GET_WORK_LOG_GROUP_FORM);

  return (
    <React.Fragment>
      {loading || dataLoading ? <CircularProgress /> : null}
      {!loading && !dataLoading && !data ?
        <Typography component="p" variant="h4">
          No data
        </Typography>
        : null }
      {data && formData ? <WorkLogGroup
        uid={uid}
        form={data.definitions.workLogGroupForm}
        initialFormData={transformFormData(data.definitions.workLogGroupForm, formData.loggedUser.worklogGroupData, parseInt(id))}
        readOnly={readOnly}
      />: null
      }
    </React.Fragment>
  );
}
