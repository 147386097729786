import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import React from "react";
import aktywni from '../images/aktywni.png';
import proo from '../images/proo.png';

export default function ProjectsLogos(props: any) {
  const page = window.location.host;
  const isAktywni = page.search('szl-aktywni') !== -1;
  const isProo = page.search('szl-proo') !== -1;
  return (
    <>
      {isAktywni ?
        <Typography variant="h6" color="text.secondary" align="center" {...props}>
          <img src={aktywni} title='Aktywni' alt='Projekt współfinansowany ze środków otrzymanych od Ministra Rodziny i Polityki Społecznej' />
        </Typography> : ''}
      {isProo ?
        <Typography variant="h6" color="text.secondary" align="center" {...props}>
          <img src={proo} title='PROO' alt='Sfinansowano ze środków Narodowego Instytutu Wolności – Centrum Rozwoju Społeczeństwa Obywatelskiego Rządowego Programu Rozwoju Organizacji Obywatelskich na lata 2018–2030 PROO' />
        </Typography> : ''}
    </>
);
}
