import * as React from "react";
import {Field, UpdateFormState} from "../../types/Form";
import {
  FormControl, FormControlLabel, FormGroup,
  FormLabel,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";

export function FormFieldCheckbox(props: { field: Field, formState: any, updateFormState: UpdateFormState }) {
  const {field, formState, updateFormState} = props;

  const currentValues =  formState.form[field.key]?.split(',') ?? [];

  function addToState(id: string) {
    currentValues.push(id);
    updateFormState({value: {
        optionValue: currentValues.join(','),
        optionString: field.key,
      }});
  }

  function removeFromState(id: string) {
    const values = currentValues.filter((value: string) => value !== id);
    updateFormState({value: {
        optionValue: values.join(','),
        optionString: field.key,
      }});
  }

  const update = (event: React.ChangeEvent<HTMLInputElement>) => {
    //event.target?.parentElement?.parentElement?.childNodes.forEach())
    if (event.target.checked) {
      addToState(event.target.id);
    } else {
      removeFromState(event.target.id);
    }
  };



  return <FormControl error={formState.errors[field.key]} fullWidth
                      sx={{mb: 2}} id={field.key} key={field.key}>
    <FormLabel >
      <Typography key={'question'}>
        {field.question}
      </Typography>
      {field.definition ? field.definition.map((value) => (
        <Typography key={'definition_' + value}
                    fontSize={"small"}>{value}</Typography>)) : null}
    </FormLabel>
    <FormGroup sx={{
      '&>label:nth-of-type(even)': {background: 'rgba(0,0,0,.05)',},
    }}>
      {field.options?.map((option) => (
        <FormControlLabel value={option.optionValue}
                          key={option.optionValue}
                          control={
                            <Checkbox
                              size="small"
                              checked = {currentValues.some((value: string) => value === option.optionValue)}
                              onChange={update}
                              id={option.optionValue}
                            />
                          }
                          label={option.optionString}/>
      ))}
    </FormGroup>
  </FormControl>;
}
