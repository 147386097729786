import {
  Accordion,
  AccordionDetails,
  AccordionSummary
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {FormField} from "./FormField";
import * as React from "react";
import {
  Section,
  Field,
  UpdateFormState,
} from "../../types/Form";
import {Warning} from "@mui/icons-material";
import {useEffect, useRef} from "react";

type props = {
  section: Section,
  expanded: boolean,
  handleSectionChange: any,
  formState: any,
  updateFormState: UpdateFormState,
}

export function FormAccordionSection(props: props) {
  const {
    section,
    expanded,
    handleSectionChange,
    formState,
    updateFormState,
  } = props;

  const title = section.title;
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (expanded && ref && ref.current) {
      setTimeout(() => {
          ref && ref.current && ref.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "start"
          })
        }, 180);
    }
  }, [expanded])
  return (
    <Accordion expanded={expanded}
               key={section.title}
               ref={ref}
               onChange={handleSectionChange(title)}>
      <AccordionSummary

        expandIcon={<ExpandMoreIcon/>}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        {formState.errors[section.title] ? <Warning color={"error"} /> : null}
        {section.title}
      </AccordionSummary>
      <AccordionDetails sx={{
        // '&>div:nth-of-type(even)': {bgcolor: '#f0f0f0',},
        // '&>div' : {boxShadow: '1px 1px gray', borderRadius: '5px'},
        '&>div>label' : {fontVariant: 'small-caps'},
        '&>div>div' : {borderLeft: '5px solid', borderColor: 'secondary.main', paddingLeft: '5px'},
      }}>
        {expanded ?
          section.fields.map((field: Field) => (
            <FormField
              field={field}
              formState={formState}
              updateFormState={updateFormState}
              key={field.key}
            />
          )) : null
        }
      </AccordionDetails>
    </Accordion>);
}
