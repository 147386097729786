import TextField from "@mui/material/TextField";
import * as React from "react";
import {Field, Update, UpdateFormState} from "../../types/Form";
import {FormFieldRadio} from "./FormFieldRadio";
import {FormFieldAnimal} from "./FormFieldAnimal";
import {FormFieldDate} from "./FormFieldDate";
import {FormFieldRating} from "./FormFieldRating";
import {FormFieldCheckbox} from "./FormFieldCheckbox";
import {FormFieldTime} from "./FormFieldTime";
import {FormFieldSelect} from "./FormFieldSelect";
import FormFieldEvaluator from "./FormFieldEvaluator";
import {FormFieldSelectGroup} from "./FormFieldSelectGroup";
import FormFieldInstructor from "./FormFieldInstructor";
import {FormFieldRadioRating} from "./FormFieldRadioRating";
import FilePicker from "../ImageUpload/FilePicker";
import FileUploadSimple from "../FileUploadSimple";
import {FormFieldFile} from "./FormFieldFile";

export function FormField(props: { field: Field, formState: any, updateFormState: UpdateFormState }) {
  const {field, formState, updateFormState} = props;
  switch (field.type) {
    case 'RADIO':
      return <FormFieldRadio
        field={field}
        formState={formState}
        updateFormState={updateFormState}
      />;
    case 'RADIO_RATING':
      return <FormFieldRadioRating
        field={field}
        formState={formState}
        updateFormState={updateFormState}
      />;
    case 'SELECT':
      return <FormFieldSelect
        field={field}
        formState={formState}
        updateFormState={updateFormState}
      />;
    case 'SELECT_GROUP':
      return <FormFieldSelectGroup
        field={field}
        formState={formState}
        updateFormState={updateFormState}
      />;
    case 'CHECKBOX':
      return <FormFieldCheckbox
        field={field}
        formState={formState}
        updateFormState={updateFormState}
      />;
    case 'RATING':
      return <FormFieldRating
        field={field}
        formState={formState}
        updateFormState={updateFormState}
        maxValue={5}
      />;
    case 'ANIMAL_SELECT':
      return <FormFieldAnimal
        field={field}
        formState={formState}
        updateFormState={updateFormState}
      />;
    case 'EVALUATOR_SELECT':
      return(<TextField
        margin="dense"
        fullWidth
        type={"number"}
        id={field.key}
        label={field.question}
        name={field.key}
        value={formState.form[field.key] ? formState.form[field.key] : ''}
        onChange={(event) => updateFormState(Update(event))}
        autoComplete={field.key}
      />);
      // return <FormFieldEvaluator
      //   field={field}
      //   formState={formState}
      //   updateFormState={updateFormState}
      // />;
    case 'INSTRUCTOR_SELECT':
      return <FormFieldInstructor
        field={field}
        formState={formState}
        updateFormState={updateFormState}
      />;
    case 'DATE':
      return <FormFieldDate
        field={field}
        formState={formState}
        updateFormState={updateFormState}
      />;
    case 'TIME':
      return <FormFieldTime
        field={field}
        formState={formState}
        updateFormState={updateFormState}
      />;
    case 'TEXTAREA':
      return (
        <TextField
          error={formState.errors[field.key]}
          margin="dense"
          fullWidth
          id={field.key}
          label={field.question}
          name={field.key}
          value={formState.form[field.key] ? formState.form[field.key] : ''}
          onChange={(event) => updateFormState(Update(event))}
          autoComplete={field.key}
          multiline
        />);
    case 'FILE':
      return (
        <FormFieldFile
          field={field}
          formState={formState}
          updateFormState={updateFormState}
        />);
    default:
      return (
        <TextField
          error={formState.errors[field.key]}
          margin="dense"
          fullWidth
          id={field.key}
          label={field.question}
          name={field.key}
          value={formState.form[field.key] ? formState.form[field.key] : ''}
          onChange={(event) => updateFormState(Update(event))}
          autoComplete={field.key}
        />);
  }
}
