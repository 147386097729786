import {gql} from "@apollo/client";

export const GET_INSTRUCTORS_LIST = gql`
  query getInstructorsList {
    loggedUser {
      instructorList {
        id
        name
      }
    }
  }
`;
