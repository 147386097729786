import * as React from "react";

export type UpdateForm = (event: React.ChangeEvent) => FieldOptions;
// export type UpdateFormState = (field: FieldOptions) => void;

export interface FormStateUpdate {
  value?: FieldOptions
  error?: any
}

export interface FormState {
  form: any,
  errors: any,
}
export type UpdateFormState = (field: FormStateUpdate) => void;

export const Update = (event: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement|HTMLSelectElement>):FormStateUpdate => {
  return {value: {
    optionValue: event.target.value,
    optionString: event.target.name,
  }}
};

export interface FieldOptions {
  optionValue: string,
  optionString: string,
}

export interface Field {
  key: string,
  question: string,
  definition: null | string[],
  type: string,
  options: null | FieldOptions[],
  value: string,
}

export interface Section {
  title: string,
  fields: Field[],
}

export interface Form {
  title: string,
  sections: Section[],
}
