import * as React from 'react';
import {WORK_LOG} from "../../App";
import {useNavigate} from "react-router-dom";
import {
  Card,
  CardActions,
  CardContent,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {DeleteForever, Restore} from "@mui/icons-material";
import {WorkLogSoloFormUid} from "../WorkLogForm/Volunteer/WorkLogSolo";
import {deleteLocalForm} from "./LocalForms";

export default function WorkLogSoloLocalCard({jsonData, setState}:any) {
  const id = jsonData.key.substring(WorkLogSoloFormUid.length);
  const json = JSON.parse(jsonData.data);
  const date = json.date;
  const navigate = useNavigate();
  return <Grid item key={id} xs={12} sm={6} md={3}>
    <Card
      sx={{height: '100%', display: 'flex', flexDirection: 'column'}}
    >
      <CardContent sx={{flexGrow: 1}}>
        <Typography gutterBottom variant="h6" component="h3">
          {date} WorkLog Volunteer
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small"
                onClick={() => navigate('/' + WORK_LOG + '/local_solo/' + id)}><Restore/></Button>
        <Button size="small"
                onClick={() => deleteLocalForm(jsonData.key, setState)}><DeleteForever/></Button>
      </CardActions>
    </Card>
  </Grid>
}
