import {gql} from "@apollo/client";

export const UPDATE_LOGGED_USER_ANIMAL_AVATAR = gql`
  mutation UpdateLoggedUserAnimalAvatar($animalId: Int!, $avatar: String!) {
    loggedUser {
      updateAnimalAvatarById(animalId: $animalId, avatar: $avatar) {
        id
        name
        dob
        species
      }
    }
  }
`;
