import {User} from "../types/User";
import {Permission} from "../types/Permission";

function userHasAnyPermission(user: User|null, allowedPriv: Permission[]) {
  return user && user.permissions.some((priv) => -1 !== allowedPriv.indexOf(priv));
}

export function isAdmin(user: User|null) {
  const allowedPriv = [Permission.Admin, ];
  return userHasAnyPermission(user, allowedPriv);
}

export function isEvaluator(user: User|null) {
  const allowedPriv = [Permission.Admin, Permission.Evaluator, Permission.EvaluatorUnderSupervision];
  return userHasAnyPermission(user, allowedPriv);
}

export function isMasterEvaluator(user: User|null) {
  const allowedPriv = [Permission.Admin, Permission.Evaluator];
  return userHasAnyPermission(user, allowedPriv);
}

export function isOrganizationAdmin(user: User|null) {
  const allowedPriv = [Permission.Admin, Permission.OrganizationAdmin];
  return userHasAnyPermission(user, allowedPriv);
}

export function isOrganizationInstructor(user: User|null) {
  const allowedPriv = [Permission.Admin, Permission.OrganizationInstructor];
  return userHasAnyPermission(user, allowedPriv);
}
