import {gql} from "@apollo/client";

export const GET_FINISHED_PADA_EXAMS = gql`
  query getPadaExams {
    evaluator {
      padaExams {
        id
        date
        evaluatorName
        evaluatorId
        addEvaluatorId
        dogName
        examPassed
        status
        certId
      }
    }
  }
`;
