import {MobileDatePicker} from "@mui/lab";
import TextField from "@mui/material/TextField";
import * as React from "react";
import {Field, UpdateFormState} from "../../types/Form";
import format from "date-fns/format";
import {DATE_FORMAT} from "../../tools/timeFomrats";

export function FormFieldDate(props: { field: Field, formState: any, updateFormState: UpdateFormState }) {
  const {field, formState, updateFormState} = props;
  const update = (newDate:Date|null) => updateFormState({value: {
    optionString: field.key,
    optionValue: newDate ? format(newDate, DATE_FORMAT) : '',
  }})
  return <MobileDatePicker
    label={field.question}
    value={new Date(formState.form[field.key])}
    onChange={(event) => update(event)}
    renderInput={(params) => <TextField fullWidth margin="dense" {...params} />}
  />;
}
