import {gql} from "@apollo/client";

export const GET_PADA_EXAM_BY_ID = gql`
  query getPadaExamById($examId: Int!) {
    evaluator {
      padaExamData(examId: $examId) {
        key
        value
      }
    }
  }
`;
