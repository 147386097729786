import * as React from "react";
import Grid from "@mui/material/Grid";
import {useMutation, useQuery} from "@apollo/client";
import {GET_LOGGED_USER_PROFILE} from "../gql/queries/getLoggedUserProfile";
import {CircularProgress, Paper} from "@mui/material";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import {UPDATE_USER_PROFILE_DATA} from "../gql/mutations/updateUserProfileData";
import {useRef, useState} from "react";
import { ImageCropper } from "./ImageCropper/ImageCropper";

export default function EditUserProfile() {
  const {loading, data} = useQuery(GET_LOGGED_USER_PROFILE);
  const [error, setError] = useState('');
  const [update] = useMutation(UPDATE_USER_PROFILE_DATA, {
    refetchQueries: [
      GET_LOGGED_USER_PROFILE
    ]
  });
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
      try {
        await update({
          variables: {
            firstName: formData.get('firstName'),
            lastName: formData.get('lastName'),
          }
        });
      } catch (e) {
        // @ts-ignore
        setError(e.message);
      }
  };

  const editor = useRef(null);

  return  <Grid container spacing={1}>
    {loading ? <CircularProgress /> : null}
    {data ?
      <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
        <TextField
          margin="normal"
          required
          fullWidth
          id="id"
          label="My ID"
          name="id"
          defaultValue={data.loggedUser.id}
          disabled={true}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          defaultValue={data.loggedUser.email}
          disabled={true}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="name"
          label="Username"
          name="username"
          defaultValue={data.loggedUser.name}
          disabled={true}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="firstName"
          label="First Name"
          name="firstName"
          defaultValue={data.loggedUser.firstName}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          id="lastName"
          label="Last Name"
          name="lastName"
          defaultValue={data.loggedUser.lastName}
        />
        <ImageCropper
          ref={editor}
        />
        { error ?
          <Alert onClose={() => setError('')} severity="error">
            {error}
          </Alert>
          : null}
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Update
        </Button>
      </Box>
      </Paper>
      : null}
  </Grid>
}
