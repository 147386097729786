import * as React from 'react';
import {useQuery} from "@apollo/client";
import {useNavigate, useParams} from "react-router-dom";
import {CircularProgress} from "@mui/material";
import {useEffect} from "react";
import  Background_en from '../../src/images/AAE_ENG.png'
import  Background_pl from '../../src/images/AAE_PL.png'
import {GET_AAT_CERTIFICATE} from "../gql/queries/getAatCertificate";

export default function AaeCertificate() {
  const params = useParams();
  const {certId} = params;
  const navigate = useNavigate();

  if (!certId || parseInt(certId) <= 0) {
    throw new Error('Invalid ID!');
  }

  const {loading, data} = useQuery(
    GET_AAT_CERTIFICATE,
    {variables: {certId: certId}}
  );

  useEffect(() => {
    if (data && data.documents) {
      const myWindow = window.open();
      myWindow?.document.write(data.documents.aatCertificate.replace(/{{image_en}}/g, Background_en)
        .replace(/{{image_pl}}/g, Background_pl));
      navigate(-1);
    }
  }, [data, navigate]);

  return (
    loading ? <CircularProgress /> : null
  );
}
