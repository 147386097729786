import {gql} from "@apollo/client";

export const GET_ORGANIZATION_WORKLOG_LIST = gql`
  query getOrganizationWorklogList {
    organizationAdmin {
      worklogList {
        id
        worklogType
        place
        date
        animalName
        userName
        timeSpent
        scan
      }
    }
  }
`;
